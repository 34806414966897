'use client';

import { motion } from 'framer-motion';
import { useParams } from 'next/navigation';
import { ReactNode } from 'react';

import Footer from '@/features/footer/footer';
import Header from '@/features/header/header';

import CpTipBanner from './components/cpTipBanner/cpTipBanner';

import style from './layout.module.scss';

function InterceptingDetail({ modal }: { modal: ReactNode }) {
    const params = useParams();

    return (
        <>
            {params.userId ? (
                <motion.div
                    id="cpModalLayout"
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    key="modalDetail"
                    className={style.modal}
                    transition={{ duration: 0.2 }}
                >
                    {modal}
                </motion.div>
            ) : null}
        </>
    );
}

export default function RootLayout({
    children,
    modal
}: Readonly<{
    children: ReactNode;
    modal: ReactNode;
}>) {
    return (
        <div className={style.homeLayout}>
            <CpTipBanner />
            <Header />
            {children}
            <Footer />
            <InterceptingDetail modal={modal} />
        </div>
    );
}
