import Link from 'next/link';
import { useEffect, useState } from 'react';

import { getNotifyUnread } from '@/api/notify/notify';
import { useUserStore } from '@/store/user';

import NoticeIcon from './img/notice.svg';

import style from './notice.module.scss';

function Notice() {
    const [unread, setUnread] = useState(0);
    const isLogin = useUserStore.use.isLogin();

    const fetchGetNotifyUnread = async () => {
        const res = await getNotifyUnread();
        if (res.status !== 200) return;
        setUnread(res.data?.count || 0);
    };
    useEffect(() => {
        if (isLogin) fetchGetNotifyUnread();
    }, [isLogin]);

    return (
        <Link className={style.notice} href="/notifyCenter">
            <NoticeIcon />
            {unread ? <span className={style.unread}>{unread > 20 ? '20+' : unread}</span> : ''}
        </Link>
    );
}

export default Notice;
