'use client';

import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useEffect, useState, MouseEvent } from 'react';

import SessionStorageManager from '@/lib/sessionStorageManager';
import { useUserStore } from '@/store/user';

import CloseIcon from './img/close.svg';
import TipBannerImage from './img/tipBanner.jpg';

import style from './cpTipBanner.module.scss';

function CpTipBanner() {
    const router = useRouter();
    const [showTip, setShowTip] = useState(false);
    const isLogin = useUserStore.use.isLogin();
    const isCp = useUserStore.use.isCp();
    const gender = useUserStore.use.userInfo()?.gender;

    useEffect(() => {
        if (isLogin && gender === 2 && !isCp) {
            setShowTip(SessionStorageManager.get('hideCpTip') ? false : true);
        }
    }, [isLogin, gender, isCp]);

    if (!showTip) return null;

    const handleJoin = () => {
        window.open('https://whywhy.cc/bacp', '_blank', 'noopener,noreferrer');
    };

    const handleClose = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        SessionStorageManager.set('hideCpTip', '1');
        setShowTip(false);
    };

    return (
        <div className={style.cpTipPlaceholder}>
            <div className={style.cpTipBanner}>
                <Image src={TipBannerImage} priority alt="WHyWHy" onClick={handleJoin} />
                <CloseIcon onClick={handleClose} className={style.closeBtn} />
            </div>
        </div>
    );
}

export default CpTipBanner;
